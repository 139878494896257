/**
 * composable to control whether we should enable
 * the floater component on hover
 *
 * only disable when:
 * on small breakpoints
 * or when the userAgent is non-desktop
 * @param {*} fromProp: to override this whole thing
 */
export default function (fromProp) {
  if (fromProp === true) return true;

  const { isXs } = useBreakpoints();
  const userAgent = useUserAgent();

  return isXs.value || userAgent.device?.type != "desktop";
}
